<template>
  <div class="bg-sub vh100">
    <div class="row">
      <div class="col-12">
        <div class="card concard card-shadow p-0">
          <div class="card-header text-center" style="height: 180px;position: relative;">
            <div class="t1 mt-3" style=" color: white">
              กรุณาแสดงหน้านี้ ณ จุดรับริสแบนด์
            </div>
            <img src="@/assets/logo.png"
              style="
                                                                                                                                  height: 120px;
                                                                                                                                  bottom: -2%;
                                                                                                                                  left: 50%;
                                                                                                                                  transform: translateX(-50%);
                                                                                                                                  position: absolute;
                                                                                                                                " />

          </div>


          <div class="text-end text-white mt-2 position-relative">
            <span @click="edit" class="title-text">แก้ไขผลตรวจATK</span><i class="bi bi-arrow-bar-right"></i>
          </div>



          <div class="container p-0 position-relative" style="text-align: center">


            <div class="white-box position-absolute w-100" :style="{ backgroundColor: card_color }">
              <img :src="ProfireLine.pictureUrl" style="
                                                              position: absolute;
                                                                                                      z-index: 99;
                                                                                                      top: 50%;
                                                                                                      left: 50%;
                                                                                                      transform: translate(-50%, -50%);
                                                                                                      height: 130px;
                                                                                                      border-radius: 50%;
                                                                                                      border: solid 2px white;
                                                                                                      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                                                                                                    " />
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <div class="container p-0 position-relative" style="text-align: center">
              <div class="text-center">
                <div class="t1" style=" color: white; font-weight: 300;">
                  ยินดีต้อนรับสู่ยาน KF8
                </div>
                <div class="t1 mt-3" style=" color: white; font-size: 25px;">
                  คุณ {{ Profire.fullname }}
                </div>



                <img :src="Profire.src" style="
                                          height: 250px;
                                          width: auto;
                                          object-fit: cover;
                                        " />


              </div>
            </div>

          </div>

   
            <div class="row position-fixed bottom-0 p-4 pb-4 text-center align-item-center">
              <img src="@/assets/sponser.png" style="
                                          object-fit: cover;
                                        " />

            </div>
      


        </div>




      </div>





    </div>
  </div>
</template>

<script>
import moment from "moment/dist/moment.js";
import { defineComponent, onMounted, inject, ref } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
import useAtk from "@/service/api/atk";


export default defineComponent({
  name: "Profile",
  setup() {

    const card_hight = "100px";
    const card_color = ref();


    const store = inject("store");
    const router = inject("router");
    const atk_img = ref([]);

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;
    const AppName = process.env.VUE_APP_NAME;
    const { GetAtkPic } = useAtk();

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      } else {
        getAtkImage(store.getters.getAccessToken.accesstoken);
      }

      if (Profire.type == "sponser") {
        card_color.value = "#F1579F";
      } else {
        if (Profire.age < 20) {
          card_color.value = '#FF0000'
        } else {
          card_color.value = '#05AD55'

        }
      }


    });

    function getAtkImage(accessToken) {
      GetAtkPic(accessToken).then((response) => {
        //  console.log(response);
        if (response.data.atk_image) {
          atk_img.value = response.data.atk_image;
          // console.log(response.atk_image);
        }
      });
    }

    const check_birthdate = (birthday) => {
      var birthdate = moment(birthday, "YYYYMMDD");
      var date_compare = moment("2022-08-27", "YYYYMMDD");
      var chk_age = moment().diff(moment(birthdate, date_compare), "years");
      //console.log(chk_age);
      return chk_age;
    };

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    return {
      edit,
      close,
      Profire,
      ProfireLine,
      AppName,
      check_birthdate,
      getAtkImage,
      atk_img,
      card_hight,
      card_color
    };
  },
});
</script>

<style scoped>

card-body {
  padding: 0 !important;
}

.image-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #ffff;
}

.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #0196c280;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}

.text-approve {
  font-size: 35px;
  text-shadow: rgba(69, 226, 37, 0.1) 0px 4px 12px;
  background: linear-gradient(to right, #33a74e 0%, #16bd77 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.white-box {
  height: 100px !important;
}
</style>
