<template>
  <div class="bg-main vh100">

    <div class="card concard card-shadow">
      <div class="card-header text-center" style=" background-color: transparent !important;">
        <img src="@/assets/logo.png" style="height: 100px; border-radius: 15%;" />
      </div>

      <div class="card-body white mt-2 ms-4 me-4 mb-40 h-80"
        style="border-radius: 20px; overflow-y: scroll; height:480px">
        <p class="t1 mb-3 text-center">
          <strong>นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Personal Data Protection Policy)
          </strong>
        </p>
        <p class="t2" style="text-indent: 40px">
          สมาคมท่องเที่ยวขนอม ได้ตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคล
          โดยมีการกำกับดูแลและการบริหารจัดการข้อมูลส่วนบุคคลให้มีความสอดคล้องกับกฎหมายคุ้มครองข้อมูลส่วนบุคคลและกฏหมายที่เกี่ยวข้อง
          สมาคมฯ จึงขอแจ้งข้อมูลดังต่อไปนี้ให้ท่านทราบ เพื่อเป็นการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
          2562 โดยมีสาระสำคัญดังต่อไปนี้

        </p>
        <ol class="t2 pl-5 sub-items mb-10" style="line-height: 180%">
          <li class="level-1">
            <strong><u>ข้อมูลส่วนบุคคล</u></strong>
            <ol class="sub-items indent">
              <li>
                <strong>ลักษณะของข้อมูลส่วนบุคคล ในเอกสารฉบับนี้</strong>
                ข้อมูลส่วนบุคคล หมายถึง ข้อมูลใด ๆ
                ที่เกี่ยวกับบุคคลธรรมดาที่ทำให้สามารถระบุถึงตัวบุคคลธรรมดานั้นได้ไม่ว่าทางตรงหรือทางอ้อม
                โดยไม่รวมถึงข้อมูลส่วนบุคคลของผู้ถึงแก่กรรม
                ข้อมูลส่วนบุคคลที่มีความอ่อนไหว หมายถึง ข้อมูลส่วนบุคคลเกี่ยวกับเชื้อชาติ เผ่าพันธุ์
                ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
                ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ (เช่น การสแกนลายนิ้วมือ การสแกนใบหน้า เป็นต้น)
                หรือข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด

              </li>

              <li>
                <strong>ข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม</strong>
                สมาคมฯ มีการเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน ดังต่อไปนี้
                <ol class="sub-items indent ms-3">
                  <li><strong>
                      ข้อมูลส่วนบุคคลทั่วไป
                    </strong>
                  </li>
                  <ol class="sub-items indent ms-3">
                    <li>
                      ข้อมูลส่วนตัว ได้แก่ ชื่อและนามสกุล, ชื่อเล่น, วันเดือนปีเกิด, เพศ,
                      ข้อมูลตามที่ระบุในบัตรประจำตัวประชาชนและหนังสือเดินทาง
                    </li>
                    <li>
                      ข้อมูลติดต่อ ได้แก่ ที่อยู่, หมายเลขโทรศัพท์, Line ID
                    </li>

                    <li>
                      ข้อมูลเกี่ยวกับการใช้งานระบบอิเล็กทรอนิกส์ ได้แก่ email, หมายเลขไอพี (IP Address) และคุกกี้
                      (Cookies)
                    </li>

                    <li>
                      ข้อมูลที่ท่านได้ให้ไว้เมื่อท่านติดต่อ หรือร่วมกิจกรรมใด ๆ กับสมาคมฯ เป็นต้น
                    </li>
                  </ol>
                </ol>

                <ol class="sub-items indent ms-3">
                  <li><strong>
                      ข้อมูลส่วนบุคคลที่มีความอ่อนไหว
                    </strong>
                  </li>
                  <ol class="sub-items indent ms-3">
                    <li>
                      ข้อมูลเกี่ยวกับเชื้อชาติ, ศาสนา
                    </li>
                    <li>
                      ข้อมูลสุขภาพ ได้แก่ ข้อมูลความพิการ, โรคประจำตัว, ยาประจำตัว, ยาที่แพ้, โรงพยาบาล, สิทธิการรักษา
                    </li>
                  </ol>
                </ol>


              </li>
            </ol>


            <ol class="sub-items indent">

            </ol>
          </li>

          <li class="level-1">
            <strong><u>การเคารพสิทธิในความเป็นส่วนบุคคล</u></strong>
            <ol class="sub-items indent">

              สมาคมฯ
              เคารพสิทธิในข้อมูลส่วนบุคคลของเจ้าของข้อมูลและตระหนักดีว่าเจ้าของข้อมูลย่อมมีความประสงค์ที่จะได้รับความมั่นคงปลอดภัยเกี่ยวกับข้อมูลของตน
              ข้อมูลส่วนบุคคลที่สมาคมฯ ได้รับมาจะถูกนำไปใช้ตามวัตถุประสงค์ที่เกี่ยวข้องเท่านั้น โดยสมาคมฯ
              มีมาตรการเข้มงวดในการรักษาความมั่นคงปลอดภัย
              ตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิชอบด้วยกฎหมาย



            </ol>
          </li>

          <li class="level-1">
            <strong><u>การเก็บรวบรวมข้อมูลส่วนบุคคล</u></strong>
            <ol class="sub-items indent">

              ในการเก็บรวบรวมข้อมูลส่วนบุคคลจากเจ้าของข้อมูลโดยตรงและการนำข้อมูลส่วนบุคคลไปใช้รวมถึงการเปิดเผยข้อมูลส่วนบุคคล
              สมาคมฯ จะขอความยินยอมจากเจ้าของข้อมูลก่อนหรือขณะทำการเก็บรวบรวม หากกฎหมายกำหนดให้ต้องขอความยินยอม
              และจะดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลเท่าที่จำเป็นตามวัตถุประสงค์ที่สมาคมฯ ระบุไว้โดยแจ้งชัด ทั้งนี้ สมาคมฯ
              อาจรวบรวมข้อมูลส่วนบุคคลที่ได้รับมาจากแหล่งอื่นที่ไม่ใช่จากเจ้าของข้อมูลส่วนบุคคลโดยตรง



            </ol>
          </li>

          <li class="level-1">
            <strong><u>วัตถุประสงค์ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล</u></strong>
            <ol class="sub-items indent">
              <strong>สมาคมฯ เก็บรวบรวมและใช้หรือเปิดเผย ข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ดังต่อไปนี้</strong>

              <li>
                เพื่อประโยชน์ในการเข้าช่วยเหลือเมื่อท่านแจ้งเหตุขอความช่วยเหลือ
              </li>
              <li>
                เพื่อประโยชน์ในการใช้บริการของสมาคมฯ
              </li>
              <li>
                เพื่อประโยชน์ในการจัดทำฐานข้อมูลสำหรับการวิเคราะห์และนำข้อมูลมาปรับปรุงบริการของสมาคมฯ
              </li>
              <li>
                เพื่อประโยชน์ในการปรับปรุงคุณภาพในการดำเนินงาน การให้บริการ และการดำเนินการที่เกี่ยวข้องกับบริการของสมาคมฯ
              </li>
              <li>
                เพื่อการวิเคราะห์และติดตามการใช้บริการทางเว็บไซต์
                และวัตถุประสงค์ในการตรวจสอบย้อนหลังในกรณีที่เกิดปัญหาการใช้งาน
              </li>
              <li>
                เพื่อวัตถุประสงค์ในการควบคุมการเข้าถึง การป้องกันและระงับอันตรายต่อชีวิต ร่างกาย
                หรือสุขภาพของท่านหรือบุคคลอื่น
              </li>
              <li>
                เพื่อใช้ในการควบคุมและป้องกันโรคติดต่อ
              </li>
              <li>
                เพื่อปฏิบัติตามกฎหมายหรือกฎระเบียบที่ใช้บังคับกับสมาคมฯ ทั้งในปัจจุบันและ ในอนาคต
              </li>
            </ol>
            <ol class="sub-items indent">
              ข้อมูลส่วนบุคคลที่สมาคมฯ
              ดำเนินการเก็บรวบรวมเพื่อวัตถุประสงค์ข้างต้นเป็นข้อมูลที่จำเป็นในการปฏิบัติตามสัญญาหรือการปฏิบัติตามกฎหมายต่าง
              ๆ ที่ใช้บังคับ หากท่านไม่ให้ข้อมูลส่วนบุคคลที่จำเป็นดังกล่าว อาจเป็นการฝ่าฝืนกฎหมาย หรือสมาคมฯ
              อาจไม่สามารถบริหารหรืออำนวยความสะดวกในการดำเนินการต่างๆ ให้กับท่านได้
            </ol>
            <ol class="sub-items indent">
              ทั้งนี้หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล สมาคมฯ จะแจ้งให้ท่านทราบ
              และดำเนินการอื่นใดตามที่กฎหมายกำหนด รวมถึงจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน
            </ol>

            <ol class="sub-items indent">
              ข้อมูลส่วนบุคคลที่สมาคมฯ
              ดำเนินการเก็บรวบรวมเพื่อวัตถุประสงค์ข้างต้นเป็นข้อมูลที่จำเป็นในการปฏิบัติตามสัญญาหรือการปฏิบัติตามกฎหมายต่าง
              ๆ ที่ใช้บังคับ หากท่านไม่ให้ข้อมูลส่วนบุคคลที่จำเป็นดังกล่าว อาจเป็นการฝ่าฝืนกฎหมาย หรือสมาคมฯ
              อาจไม่สามารถบริหารหรืออำนวยความสะดวกในการดำเนินการต่างๆ ให้กับท่านได้
            </ol>

            <ol class="sub-items indent">
              ทั้งนี้หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล สมาคมฯ จะแจ้งให้ท่านทราบ
              และดำเนินการอื่นใดตามที่กฎหมายกำหนด รวมถึงจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน
            </ol>
          </li>

          <li class="level-1">
            <strong><u>ระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคล</u></strong>
            <ol class="sub-items indent">

              สมาคมฯ
              จะเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาเท่าที่จำเป็นเพื่อวัตถุประสงค์ในการเก็บรวบรวมและใช้หรือเปิดเผยข้อมูลส่วนบุคคลซึ่งได้ระบุไว้ในคำประกาศฉบับนี้
              ตามหลักเกณฑ์ที่ใช้กำหนดระยะเวลาเก็บ ได้แก่ ระยะเวลาที่สมาคมฯ
              ยังมีความสัมพันธ์กับท่านในฐานะผู้ใช้บริการของสมาคมฯ
              และอาจเก็บต่อไปตามระยะเวลาที่จำเป็นเพื่อการปฏิบัติตามกฎหมายหรือตามอายุความทางกฎหมาย
              เพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย การปฏิบัติตามกฎหมายหรือการใช้สิทธิเรียกร้องตามกฎหมาย
              หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย หรือเพื่อเหตุอื่นตามนโยบายและข้อกำหนดภายในของสมาคมฯ


            </ol>

            <ol class="sub-items indent">

              ในกรณีที่ไม่สามารถระบุระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคลได้ชัดเจน สมาคมฯ
              จะเก็บรักษาข้อมูลไว้ตามระยะเวลาที่อาจคาดหมายได้ตามมาตรฐานของการเก็บรวบรวม (เช่น
              อายุความตามกฎหมายทั่วไปสูงสุด 10 ปี)

            </ol>
          </li>

          <li class="level-1">
            <strong><u>การรักษาความมั่นคงปลอดภัย</u></strong>
            <ol class="sub-items indent">
              สมาคมฯ
              กำหนดให้มีมาตรการที่เข้มงวดในการรักษาความมั่นคงปลอดภัยตามนโยบายและแนวปฏิบัติในการรักษาความมั่นคงปลอดภัยด้านเทคโนโลยีสารสนเทศของสมาคมฯ
              เพื่อป้องกันการสูญหาย การเข้าถึง ทำลาย ใช้ แปลง
              แก้ไขหรือมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยไม่มีสิทธิหรือไม่ชอบด้วยกฎหมาย

            </ol>
          </li>

          <li class="level-1">
            <strong><u>สิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล</u></strong>
            <ol class="sub-items indent">
              ในฐานะที่เป็นเจ้าของข้อมูลส่วนบุคคลท่านมีสิทธิตามที่กำหนดไว้โดยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
              2562 รวมถึงสิทธิต่าง ๆ ดังนี้

              <li>
                สิทธิในการเพิกถอนความยินยอม
                ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับสมาคมฯ ได้
                เว้นแต่การเพิกถอนความยินยอมจะมีข้อจำกัดโดยกฎหมายหรือสัญญาที่ให้ประโยชน์แก่ท่าน ในฐานะที่ท่านเป็นเจ้าของ
              </li>
              <li>
                สิทธิในการขอเข้าถึงข้อมูลส่วนบุคคล
                ท่านมีสิทธิขอเข้าถึงและขอรับสำเนาข้อมูลของท่านซึ่งอยู่ในความรับผิดชอบของสมาคมฯ รวมถึงขอให้สมาคมฯ
                เปิดเผยการได้มาซึ่งข้อมูลดังกล่าวที่ท่านไม่ได้ให้ความยินยอมต่อสมาคมฯ ได้
              </li>
              <li>
                สิทธิในการขอให้ส่งหรือโอนข้อมูลส่วนบุคคล ท่านมีสิทธิขอให้สมาคมฯ
                โอนข้อมูลส่วนบุคคลของท่านที่ท่านให้ไว้กับสมาคมฯ ได้ตามที่กฎหมายกำหนด
              </li>
              <li>
                สิทธิในการคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
                ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลที่เกี่ยวกับท่านสำหรับกรณีการเก็บรวบรวม ใช้
                หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตนได้ตามที่กฎหมายกำหนด
              </li>
              <li>
                สิทธิในการขอลบข้อมูลส่วนบุคคล ท่านมีสิทธิขอให้สมาคมฯ
                ลบหรือทำลายหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ตามที่กฎหมายกำหนด
              </li>
              <li>
                สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล ท่านมีสิทธิขอให้สมาคมฯ
                ระงับการใช้ข้อมูลของท่านได้ตามที่กฎหมายกำหนด
              </li>
              <li>
                สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง กรณีที่ท่านเห็นว่าข้อมูลที่สมาคมฯ
                มีอยู่นั้นไม่ถูกต้องหรือท่านมีการเปลี่ยนแปลงข้อมูลส่วนบุคคลของท่านเอง ท่านมีสิทธิขอให้สมาคมฯ
                แก้ไขข้อมูลส่วนบุคคลของท่านเพื่อให้ข้อมูลส่วนบุคคลดังกล่าวถูกต้อง เป็นปัจจุบัน สมบูรณ์
                และไม่ก่อให้เกิดความเข้าใจผิด
              </li>
              <li>
                สิทธิในการรับทราบกรณีมีการแก้ไขเปลี่ยนแปลงแบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคลของท่าน สมาคมฯ
                อาจมีการพิจารณาทบทวนและแก้ไขเปลี่ยนแปลงแบบแจ้งนี้ตามความเหมาะสม
                ในบางครั้งเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลของท่านจะได้รับความคุ้มครองอย่างเหมาะสม
              </li>
              <li>
                9 สิทธิในการร้องเรียน
                ท่านมีสิทธิในการร้องเรียนต่อพนักงานเจ้าหน้าที่ผู้มีอำนาจตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                หากสมาคมฯ ฝ่าฝืนหรือไม่ปฏิบัติตามพระราชบัญญัติดังกล่าวได้
                ในกรณีที่เจ้าของข้อมูลส่วนบุคคลยื่นคำร้องขอใช้สิทธิภายใต้บทบัญญัติของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                พ.ศ. 2562 เมื่อสมาคมฯ ได้รับคำร้องขอดังกล่าวแล้ว จะดำเนินการภายในระยะเวลาที่กฎหมายกำหนด อนึ่ง สมาคมฯ
                สงวนสิทธิที่จะปฏิเสธหรือไม่ดำเนินการตามคำร้องขอดังกล่าวได้ในกรณีที่กฎหมายกำหนด
                ในกรณีที่เจ้าของข้อมูลมีข้อจำกัดโดยเลือกที่จะให้ข้อมูลส่วนบุคคลเฉพาะอย่าง อาจส่งผลให้
                ไม่สามารถได้รับบริการจากสมาคมฯได้อย่างเต็มที่ รวมทั้งสมาคมฯ
                อาจจะไม่สามารถทำงานร่วมกับเจ้าของข้อมูลส่วนบุคคลหรือให้บริการใด ๆ ได้
                หากเจ้าของข้อมูลส่วนบุคคลไม่ยินยอมให้ข้อมูลที่สมาคมฯ ต้องการ
              </li>
            </ol>
          </li>

          <li class="level-1">
            <strong><u>การเปิดเผยข้อมูลส่วนบุคคลกับบุคคลอื่นหรือหน่วยงานอื่น</u></strong>
            <ol class="sub-items indent">
              สมาคมฯ
              อาจมีความจำเป็นในการเปิดเผยข้อมูลส่วนบุคคลให้กับหน่วยงานราชการหรือหน่วยงานภาครัฐตามข้อบังคับของกฎหมายหรือตามคำสั่งศาลหรือตามคำสั่งเจ้าหน้าที่ผู้มีอำนาจ
              โดยข้อมูลส่วนบุคคลจะได้รับการเก็บรักษาเป็นความลับ ทั้งในรูปเอกสารและข้อมูลอิเล็กทรอนิกส์
              รวมทั้งในระหว่างการส่งผ่านข้อมูลทุกขั้นตอน
            </ol>
            <ol class="sub-items indent">
              ทั้งนี้ ในกรณีที่ต้องมีการโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ สมาคมฯ
              จะดำเนินการตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 กำหนดไว้อย่างเคร่งครัด
            </ol>
          </li>

          <li class="level-1">
            <strong><u>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</u></strong>
            <ol class="sub-items indent">
              สมาคมฯ ได้มีการดำเนินการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              โดยแต่งตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer : DPO)
              เพื่อตรวจสอบการดำเนินการของสมาคมฯ ที่เกี่ยวกับการเก็บรวบรวม ใช้
              และเปิดเผยข้อมูลส่วนบุคคลให้สอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              รวมถึงกฎหมายที่เกี่ยวข้องกับการคุ้มครองข้อมูลส่วนบุคคล
            </ol>
          </li>



          <li class="level-1">
            <strong><u>วิธีการติดต่อ</u></strong>
            <ol class="sub-items indent">
              ในกรณีที่มีข้อสงสัยหรือต้องการสอบถามรายละเอียดเพิ่มเติมเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของท่าน
              โปรดติดต่อสมาคมฯ ได้ตามช่องทางดังต่อไปนี้
              สมาคมท่องเที่ยวขนอม 97/20 ม.4 ต.ขนอม อ.ขนอม จ.นครศรีธรรมราช 80210

            </ol>
          </li>

          <li class="level-1">
            <strong><u>การเปลี่ยนแปลงนโยบายและแนวปฏิบัติคุ้มครองข้อมูลส่วนบุคคล</u></strong>
            <ol class="sub-items indent">
              สมาคมฯ จะทำการพิจารณาทบทวนเงื่อนไขนโยบายสมาคมฯ ฉบับนี้เป็นครั้งคราว เพื่อให้สอดคล้องกับแนวปฏิบัติ
              และกฎหมายที่เกี่ยวข้อง หากมีการแก้ไขเปลี่ยนแปลงสมาคมฯ
              จะแจ้งให้ทราบด้วยการเผยแพร่ผ่านการประกาศที่เหมาะสมของสมาคมฯ
            </ol>
          </li>



        </ol>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-center align-center">
          <button @click="next" class="button">ยอมรับ</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
export default defineComponent({
  name: "Register",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const name_line = ref(process.env.VUE_APP_NAME_LINE);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const next = () => {
      let form = {
        id_line: store.getters.getAccessToken.accesstoken,
        agreement: true,
      };
      store.dispatch(SET_REGISTER, form);
      router.push({ name: "Register_1" });
    };

    return {
      name_line,
      next,
    };
  },
});
</script>

<style scoped></style>
